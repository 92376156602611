
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import { defineComponent } from "vue";
import {
  EmailValidationResponse,
  validationTools,
} from "@/mixins/validationTools";
import UserNotFound from "@/components/search-users/UserNotFound.vue";
import Searching from "@/components/search-users/Searching.vue";
import ProfileCard from "../profile/ProfileCard.vue";
import ProfilePictureGalleryModal from "../profile/ProfilePictureGalleryModal.vue";
import { CarrerSummaryTable } from "../unilevel";
import { errorModal, informationModal } from "../modals";
import { mapGetters } from "vuex";
import { SearchUserResponse } from "../../store/User/UserInterfaces";
import * as bs from "bootstrap";
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
  subscriptionType,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";

export default defineComponent({
  name: "search-user",
  props: {
    lastQuote: { type: Object, required: true },
    isPaid: { type: Boolean, required: true },
    isQuoting: { type: Boolean, required: true },
  },
  components: {
    UserNotFound,
    Searching,
    ProfileCard,
    ProfilePictureGalleryModal,
    CarrerSummaryTable,
    errorModal,
    informationModal,
  },
  emits: ["clearQuote", "requestQuote", "paid"],
  data() {
    return {
      timer: undefined as NodeJS.Timeout | undefined,
      emailModalObj: {} as any,
      informationModal: {} as any,
      errorModal: {} as any,
      searching: false,
      notFound: false,
      activating: false,
      username: "",
      daysLeft: 0,
      actions: {
        changeEmail: false,
        gtrBasic: false,
        distributor: false,
        noVolume: false,
        gtrVip: false,
        cmmi: false,
        gtrLite: false,
        liteDistributor: false,
        upgradeToBasic: false,
        upgradeToVip: false,
      },
      newEmail: "",
      userData: {} as SearchUserResponse,
      emailValidation: new EmailValidationResponse(),
      errorTitle: "",
      errorMessage: "",
      informationTitle: "",
      informationMessage: "",
    };
  },
  computed: {
    ...mapGetters("account", ["getPromotions"]),
    ...mapGetters("auth", ["getUsernamePK", "getUseremail"]),
    ...mapGetters("subs", [
      "getSubscriptionConfig",
      "getSubscriptionsUpgrade",
      "getSubscriptionUpgrade",
    ]),
    ...mapGetters("sys", ["isOffline"]),
    canChangeEmail(): boolean {
      return (
        this.emailValidation.canChange &&
        this.newEmail.length > 0 &&
        this.newEmail !== this.userData.contact.email.data
      );
    },
  },
  methods: {
    searchUser() {
      this.searching = true;
      this.notFound = false;
      this.userData = {} as SearchUserResponse;
      this.actions.changeEmail = false;
      this.actions.gtrBasic = false;
      this.actions.distributor = false;
      this.actions.noVolume = false;
      this.actions.gtrVip = false;
      this.actions.cmmi = false;
      this.actions.gtrLite = false;
      this.actions.liteDistributor = false;
      this.actions.upgradeToBasic = false;
      this.actions.upgradeToVip = false;
      this.daysLeft = 0;

      const searchUserFunction = firebase
        .functions()
        .httpsCallable("Users-searchUser");
      searchUserFunction({ username: this.username })
        .then((result) => {
          const response = result.data;

          if (response.OPCODE == "SUCCESS") {
            const user: SearchUserResponse = response.data;
            user.subscriptions = new Map(Object.entries(user.subscriptions));

            if (user.metadata != undefined) {
              user.metadata.creationTime = new Date(user.metadata.creationTime);
              user.metadata.lastSignInTime = new Date(
                user.metadata.lastSignInTime
              );
              user.metadata.lastRefreshTime = new Date(
                user.metadata.lastRefreshTime
              );
            }

            if (user.scholarshipData != undefined) {
              const endDateTimeStamp: any = user.scholarshipData.periodEndDate;
              user.scholarshipData.periodEndDate =
                new firebase.firestore.Timestamp(
                  endDateTimeStamp._seconds,
                  endDateTimeStamp._nanoseconds
                ).toDate();
            }

            this.actions.noVolume =
              user.subscriptions?.size == 0 &&
              (this.getPromotions.both > 0 ||
                (this.getPromotions.tradingAcademy > 0 &&
                  this.getPromotions.distributorLicense > 0)) &&
              user.usernamePK != this.getUsernamePK;

            let userPackage: string =
              user.subscriptionPackage.package.packageId;
            userPackage =
              userPackage == "gtr-basic" ? "trading-academy" : userPackage;
            this.daysLeft = user.subscriptions.get(userPackage) || 0;

            if (user.subscriptionPackage.package.packageId == "none") {
              this.actions.gtrBasic = true;
              this.actions.gtrVip = true;
              this.actions.gtrLite = true;
            }

            if (user.subscriptionPackage.package.packageId == "gtr-lite") {
              if (user.subscriptionPackage.package.isActive === false) {
                this.actions.gtrLite = true;
                this.actions.gtrBasic = true;
              }
              if (user.subscriptionPackage.package.isActive === true) {
                this.actions.liteDistributor = true;

                const remainingDaysLite =
                  user.subscriptions.get("gtr-lite") || 30;
                const gtrLiteDuration =
                  this.getSubscriptionConfig("gtr-lite").duration || 99999;

                const daysToBasic =
                  this.getSubscriptionsUpgrade("lite-to-basic-upgrade")
                    ?.upgradeWindowDays || 0;

                // const daysToVip =
                //   this.getSubscriptionsUpgrade("lite-to-vip-upgrade")
                //     ?.upgradeWindowDays || 0;

                if (remainingDaysLite < 3) {
                  this.actions.gtrLite = true;
                }

                if (gtrLiteDuration - daysToBasic < remainingDaysLite) {
                  this.actions.upgradeToBasic = true;
                }
                // if (
                //   gtrLiteDuration - daysToVip < remainingDaysLite &&
                //   user.subscriptions.get("mmi-colombia") === undefined &&
                //   user.subscriptions.get("trading-academy") === undefined
                // ) {
                //   this.actions.upgradeToVip = true;
                // }
              }
            }

            if (user.subscriptionPackage.package.packageId == "gtr-basic") {
              if (user.subscriptionPackage.package.isActive === false) {
                this.actions.gtrLite = true;
                this.actions.gtrBasic = true;
              }
              if (user.subscriptionPackage.package.isActive === true) {
                this.actions.cmmi = true;
                this.actions.distributor = true;

                const basicDaysLeft =
                  user.subscriptions.get("trading-academy") || 30;
                if (basicDaysLeft < 3) {
                  this.actions.gtrBasic = true;
                }
              }
            }

            if (user.subscriptionPackage.package.packageId == "gtr-vip") {
              if (user.subscriptionPackage.package.isActive === false) {
                this.actions.gtrLite = true;
                this.actions.gtrBasic = true;
              }
              if (user.subscriptionPackage.package.isActive === true) {
                this.actions.distributor = true;

                const basicDaysLeft =
                  user.subscriptions.get("trading-academy") || 30;
                if (basicDaysLeft < 3) {
                  this.actions.gtrBasic = true;
                }
              }
            }

            const creationDate = user.metadata?.creationTime.getTime();
            this.actions.changeEmail =
              user.enroller == this.getUsernamePK &&
              creationDate != undefined &&
              Math.abs(
                new Date(creationDate).getTime() - new Date().getTime()
              ) /
                36e5 <
                24;
            this.userData = user;
          } else if (response.OPCODE == "ERROR") {
            const error = response.error;
            if (error.name == "USER_NOT_FOUND") {
              this.notFound = true;
            } else {
              alert(error.message);
            }
          }
          this.searching = false;
        })
        .catch((error) => {
          console.error(error);
          alert(
            "Error al llamar la función de búsqueda de usuarios: " +
              error.message
          );
          this.searching = false;
        });
    },
    showChangeEmailModal() {
      this.emailModalObj.show();
      return true;
    },
    validateEmailInput() {
      if (this.timer !== undefined) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        //console.log(this.newEmail);
        if (this.newEmail !== this.userData.contact.email.data) {
          this.emailValidation = this.validateEmail(this.newEmail);
        } else {
          this.emailValidation.hasWarning = false;
          this.emailValidation.canChange = false;
          this.emailValidation.hasError = true;
          this.emailValidation.userMessage =
            "El correo electrónico no puede ser el mismo";
        }
        clearTimeout(this.timer as NodeJS.Timeout);
        this.timer = undefined;
      }, 500);
    },
    changeEmail() {
      this.errorTitle = "";
      this.errorMessage = "";
      this.informationTitle = "";
      this.informationMessage = "";
      if (!this.canChangeEmail) {
        this.errorTitle = "Error";
        this.errorMessage =
          "El correo electrónico no puede estar vacío o ser igual al actual.";
        this.errorModal.show();
        return;
      }

      const changeDownlineEmail = firebase
        .functions()
        .httpsCallable("Users-changeDownlineEmail");
      changeDownlineEmail({
        username: this.userData.usernamePK,
        email: this.newEmail.toLowerCase(),
      }).then((response) => {
        if (response.data.OPCODE == "SUCCESS") {
          this.userData.contact.email.data = this.newEmail;
          this.emailModalObj.hide();
          this.newEmail = "";
          this.informationTitle = "Correo electrónico cambiado exitosamente";
          this.informationMessage =
            "El correo electrónico del usuario se ha cambiado exitosamente.";
          this.informationModal.show();
        } else {
          const email = this.newEmail;
          this.emailModalObj.hide();
          this.errorTitle = "Error al cambiar el correo electrónico";
          this.errorMessage = response.data.error.message;
          // console.log(response.data.error);
          this.errorModal.show();

          const errorModalElement = document.getElementById("error-modal");
          errorModalElement?.addEventListener("hidden.bs.modal", () => {
            this.errorTitle = "";
            this.errorMessage = "";
            this.newEmail = email;
            this.validateEmailInput();
            this.emailModalObj.show();
          });
          const emailModalElement =
            document.getElementById("change-email-modal");
          emailModalElement?.addEventListener("hidden.bs.modal", () => {
            this.newEmail = "";
            this.emailValidation = new EmailValidationResponse();
            errorModalElement?.addEventListener("hidden.bs.modal", () => {
              this.errorTitle = "";
              this.errorMessage = "";
            });
          });
        }
      });
    },
    SubscriptionConfig(subscriptionId: subscriptionType): SubscriptionInfo {
      return this.getSubscriptionConfig(subscriptionId);
    },
    subscriptionQuote(
      subscriptionId: subscriptionType,
      promotionType?: "promotion" | "upgrade",
      promotionId?: string
    ) {
      const affiliateUsername = this.userData.usernamePK;
      const fiveMinutes = new Date();
      fiveMinutes.setTime(fiveMinutes.getTime() + 5 * 60 * 1000); //5 minutes from now
      if (
        !(
          this.lastQuote.subscriptionId == subscriptionId &&
          this.lastQuote.username == affiliateUsername &&
          this.lastQuote.expirationTime > fiveMinutes
        )
      ) {
        this.$emit("clearQuote");
        const subscriptionConfig = this.SubscriptionConfig(subscriptionId);
        const promotionConfig = this.getSubscriptionsUpgrade(promotionId);

        const request: QuoteSubscriptionRequest = {
          subscriptionId: subscriptionId,
          promotionType: promotionType,
          promotionId: promotionId,
          duration: promotionId
            ? this.daysLeft + 1 // DaysLeft are rounded down, so we add 1 to get the correct value
            : subscriptionConfig.duration,
          durationUnit: promotionId
            ? promotionConfig.durationUnit
            : subscriptionConfig.durationUnit,
          username: affiliateUsername,
          email: this.getUseremail,
          isDirectAffiliate: false,
        };
        this.$emit("requestQuote", request);
      }
    },
    registerWithNoVolume() {
      this.activating = true;
      const subscriptionRequest = {
        subscriptionId: "both",
        username: this.userData.usernamePK,
        email: this.getUseremail,
        isDirectAffiliate: false,
        noVolume: true,
      };
      const UpdateSubscription = firebase
        .functions()
        .httpsCallable("Subscriptions-UpdateSubscriptionWithoutVolume");
      UpdateSubscription(subscriptionRequest)
        .then((response) => {
          if (response.data.OPCODE == "SUCCESS") {
            this.searchUser();
            this.$emit("paid");
            this.informationTitle = "Activación exitosa";
            this.informationMessage = "El usuario se ha activado exitosamente.";
            this.informationModal.show();
          } else if (response.data.OPCODE == "ERROR") {
            this.errorTitle = "Error al activar el usuario";
            this.errorMessage = response.data.message + "\n\r";
            this.errorMessage += response.data.messageDetails;
            this.errorModal.show();
          }
        })
        .finally(() => {
          this.activating = false;
        });
    },
  },
  mounted() {
    const emailModalElement = document.getElementById("change-email-modal");
    this.emailModalObj = bs.Modal.getOrCreateInstance(emailModalElement);
    emailModalElement?.addEventListener("hidden.bs.modal", () => {
      this.newEmail = "";
      this.emailValidation = new EmailValidationResponse();
    });

    const infModalElement = document.getElementById("information-modal");
    this.informationModal = bs.Modal.getOrCreateInstance(infModalElement);
    infModalElement?.addEventListener("hidden.bs.modal", () => {
      this.informationTitle = "";
      this.informationMessage = "";
    });

    const errorModalElement = document.getElementById("error-modal");
    this.errorModal = bs.Modal.getOrCreateInstance(errorModalElement);
    errorModalElement?.addEventListener("hidden.bs.modal", () => {
      this.errorTitle = "";
      this.errorMessage = "";
    });
  },
  setup() {
    const validateEmail = validationTools.methods.validateEmail;
    return {
      validateEmail,
    };
  },
});
